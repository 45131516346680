<template>
    <div id="asset-card-minimalist-component" class="h-100">
        <!-- MOBILE -->
        <template v-if="is_mobile">
            <v-badge
                :content="
                    $store.getters['orders_cockpit/orders_cockpit_pending_validation'].filter(
                        (order) => order.asset.id_asset === asset.id_asset
                    ).length
                "
                :value="
                    $store.getters['orders_cockpit/orders_cockpit_pending_validation'].filter(
                        (order) => order.asset.id_asset === asset.id_asset
                    ).length
                "
                class="w-100"
                color="error"
                offset-x="30"
                offset-y="10"
            >
                <div class="text-center">
                    <div class="font-12 text-overflow-ellipsis pa-1">
                        <div :class="`asset-bar ${is_selected ? 'asset-bar-selected' : ''} mb-1`" data-cy="asset-bar">
                            <v-progress-linear
                                :buffer-value="Math.round((asset.actual_volume / asset.volume_total) * 100)"
                                :color="is_selected ? (asset.asset_liquid ? asset.asset_liquid.color : 'primary') : '#cccccc'"
                                :height="6"
                                :value="Math.round((asset.actual_volume / asset.volume_total) * 100)"
                                rounded
                            />
                        </div>
                        {{ asset.name }}
                    </div>

                    <div class="font-10 font-color-light line-height-12">
                        <v-icon x-small> mdi-map-marker-outline</v-icon>
                        {{ asset.domicile.address }}<br />
                        {{ asset.domicile.postal_code }} {{ asset.domicile.city }}
                    </div>
                </div>
            </v-badge>
        </template>

        <!-- DESKTOP -->
        <template v-else>
            <div class="position-relative h-100">
                <v-card
                    :class="`${is_selected ? 'minimalist-asset-card-selected' : 'minimalist-asset-card'} h-100 desktop-asset-card`"
                    data-cy="minimalist-asset-card"
                    elevation="0"
                >
                    <v-card-text class="h-100 position-relative d-flex justify-space-between flex-column gap-2">
                        <div>
                            <p class="mb-0 font-weight-bold font-18 text-overflow-ellipsis font-color-default">
                                {{ asset.name }}
                            </p>

                            <p class="font-12 ml-n1 d-flex line-height-16 min-height-32 mb-0">
                                <v-icon class="font-color-medium align-stretch" dense> mdi-map-marker-outline</v-icon>
                                <span class="font-color-medium" data-cy="tank-card-address">
                                    {{ getFormattedAddress(asset.domicile) }}
                                </span>
                            </p>
                        </div>

                        <template v-if="asset.is_subscription_device_active">
                            <div class="d-flex justify-space-between align-center gap-1">
                                <display-asset-level-component :asset="asset" :colorless="!is_selected" :scale="0.6" />

                                <div class="font-12 line-height-16 font-color-default">
                                    {{ Math.round((asset.actual_volume / asset.volume_total) * 100) }} % <br />
                                    <span class="white-space-nowrap"> {{ numberWithSpaces(Math.round(asset.actual_volume), 0) }} L </span>
                                    /
                                    <span class="white-space-nowrap"> {{ numberWithSpaces(Math.round(asset.volume_total), 0) }} L </span>
                                </div>
                            </div>

                            <template v-for="device in asset.devices">
                                <div :key="device.id_device" class="d-flex justify-space-between">
                                    <chips-last-statement
                                        :date="device.date_last_emission"
                                        :fallback_text="$t('error_display.no_emission')"
                                    />

                                    <display-device-battery-level-component
                                        v-if="asset.is_subscription_device_active"
                                        :battery="device.battery"
                                    />
                                </div>
                            </template>
                        </template>

                        <template v-else>
                            <div class="d-flex align-center justify-center flex-column gap-1 h-70">
                                <v-icon class="font-color-light" x-large>mdi-cancel</v-icon>
                                <p class="font-color-light text-uppercase mb-1">
                                    {{ $t('asset_card_minimalist_component.subscription_expired') }}
                                </p>
                            </div>
                        </template>
                    </v-card-text>
                </v-card>

                <div id="order-tooltip-wrapper" class="order-tooltip-wrapper absolute -top-4 -right-4" data-cy="order-tooltip-wrapper">
                    <v-btn
                        v-if="
                            $store.getters['orders_cockpit/orders_cockpit_pending_validation'].filter(
                                (order) => order.asset.id_asset === asset.id_asset
                            ).length === 1
                        "
                        class="unsolicited-btn bounce-btn"
                        color="error"
                        elevation="0"
                        fab
                        small
                    >
                        <v-icon color="white">mdi-tanker-truck</v-icon>
                    </v-btn>

                    <div id="order-tooltip-content" class="order-tooltip-content" data-cy="order-tooltip-content">
                        <span class="font-weight-bold secondary--text">
                            {{ $t('list_tank_equipmement_component.have_order_proposal_to_validate') }}
                        </span>

                        <v-btn class="font-weight-bold mt-2" color="primary" @click="$router.push('orders_cockpit')">
                            <span class="secondary--text">{{ $t('list_tank_equipmement_component.access') }}</span>
                        </v-btn>

                        <div id="order-tooltip-content-arrow" class="order-tooltip-content-arrow"></div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import ChipsLastStatement from '@/components/Global/ChipsLastStatement.vue'
import DisplayAssetLevelComponent from '@/components/Equipment/LiquidLevelComponent/DisplayAssetLevelComponent.vue'
import DisplayDeviceBatteryLevelComponent from '@/components/Global/DisplayDeviceBatteryLevelComponent.vue'

export default {
    name: 'AssetCardMinimalistComponent',
    components: { DisplayDeviceBatteryLevelComponent, DisplayAssetLevelComponent, ChipsLastStatement },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        is_selected: {
            type: Boolean,
            required: true,
        },
    },
    created() {
        this.avoidTooltipOffScreen()
    },
    methods: {
        avoidTooltipOffScreen() {
            setTimeout(() => {
                let tooltips = document.getElementsByClassName('order-tooltip-content')
                let arrows = document.getElementsByClassName('order-tooltip-content-arrow')
                Array.prototype.forEach.call(document.getElementsByClassName('order-tooltip-wrapper'), (el, index) => {
                    //avoid error during fast navigation
                    if (this.$router.currentRoute.name === 'equipment') {
                        el.addEventListener('mouseover', (e) => {
                            if (window.innerWidth < e.pageX + 160) {
                                tooltips[index].style.left = '-85px'
                                arrows[index].style.left = '70%'
                            } else {
                                tooltips[index].style.left = '-55px'
                                arrows[index].style.left = '50%'
                            }
                        })
                    }
                })
            }, 500)
        },
    },
}
</script>

<style>
.asset-bar-selected {
    outline: 2px solid var(--v-secondary-base) !important;
}

.asset-bar {
    outline: 2px solid var(--v-default_text_light-base);
    padding: 2px;
    border-radius: 500px;
}

.order-tooltip-content {
    visibility: hidden;
    position: absolute;
    background: white;
    width: 150px;
    bottom: 50px;
    left: -55px;
    padding: 10px;
    border-radius: 2px;
    text-align: center;
    filter: drop-shadow(0 0 2px rgb(0 0 0 / 20%));
    opacity: 0;
    z-index: 99;
    display: none;
}

.order-tooltip-content-arrow {
    content: ' ';
    position: absolute;
    bottom: -10px; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}

.order-tooltip-wrapper:hover .order-tooltip-content {
    visibility: visible;
    opacity: 1;
    display: block;
}
</style>
