<template>
    <div id="device-traited-data-by-device-referentiel-metric-datatable-widget-component">
        <v-card class="elevation-0">
            <v-card-title>
                <header-widget-component
                    :actions="[
                        {
                            title: $t('device_traited_data_by_device_referentiel_metric_datatable_widget_component.download_list_statements'),
                            icon: 'mdi-download',
                            emit: 'downloadDeviceDataCsv',
                            has_access: userHasAccess('TNK', role_vision),
                        },
                    ]"
                    :is_open="is_open"
                    :title="$t('device_traited_data_by_device_referentiel_metric_datatable_widget_component.list_statements')"
                    widget_name="device-traited-data-by-device-referentiel-metric-datatable-widget-component"
                    v-on:downloadDeviceDataCsv="downloadDeviceDataCsv"
                />
            </v-card-title>

            <v-expand-transition>
                <div v-show="is_open">
                    <v-card-text class="pb-4">
                        <v-data-table
                            :footer-props="{
                                'items-per-page-options': [5, 10, 25, 50],
                            }"
                            :headers="headers"
                            :items="device_traited_data"
                            :items-per-page="item_per_page"
                            :loading="fetching_dtd || downloading_device_data_csv"
                            :page="page"
                            :server-items-length="total_rows"
                            loader-height="2"
                            v-on:update:page="handlePageUpdate"
                            v-on:update:items-per-page="handleItemsPerPageUpdate"
                        >
                            <template v-slot:item.date_releve="{ item }">
                                <div class="pa-2">
                                    <p class="white-space-nowrap mb-0">
                                        {{ item.date_releve }}
                                    </p>
                                    <chips-last-statement :date="item.date_releve" />
                                </div>
                            </template>

                            <template
                                v-for="(header, index) in headers.slice(1, headers.length)"
                                v-slot:[`item.${header.value}`]="{ item }"
                            >
                                <div :key="index">
                                    <template v-if="header.value === 'declencheur'">
                                        {{ displayToHumanTrigger(item[header.value]) }}
                                    </template>

                                    <template v-else-if="header.value === 'erreur'">
                                        {{ displayToHumanError(item[header.value]) }}
                                    </template>

                                    <template v-else-if="header.value === 'batterie'">
                                        <display-device-battery-level-component :battery="item[header.value]" />
                                    </template>

                                    <template v-else-if="header.value === 'quality_network'">
                                        <div class="display-flex-content gap-1 line-height-16">
                                            <template v-if="item[header.value] === null">
                                                <v-icon color="disabled">mdi-network-strength-4-cog</v-icon>
                                                {{ $t('global.no_data') }}
                                            </template>

                                            <template
                                                v-else-if="item[header.value] === 0 || item[header.value] === 1 || item[header.value] === 2"
                                            >
                                                <v-icon color="error">mdi-network-strength-1-alert</v-icon>
                                                {{ $t('global.bad') }}
                                            </template>

                                            <template v-else-if="item[header.value] === 3">
                                                <v-icon color="warning">mdi-network-strength-3-alert</v-icon>
                                                {{ $t('global.medium') }}
                                            </template>

                                            <template v-else-if="item[header.value] === 4">
                                                <v-icon color="success">mdi-network-strength-3</v-icon>
                                                {{ $t('global.good') }}
                                            </template>

                                            <template v-else-if="item[header.value] === 5">
                                                <v-icon color="success">mdi-network-strength-4</v-icon>
                                                {{ $t('global.very_good') }}
                                            </template>
                                        </div>
                                    </template>

                                    <template v-else>
                                        {{ item[header.value] }}
                                    </template>
                                </div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </div>
            </v-expand-transition>
        </v-card>
    </div>
</template>

<script>
import DeviceRepository from '@/repositories/DeviceRepository'
import ChipsLastStatement from '@/components/Global/ChipsLastStatement.vue'
import DisplayDeviceBatteryLevelComponent from '@/components/Global/DisplayDeviceBatteryLevelComponent.vue'
import HeaderWidgetComponent from '@/components/Widget/HeaderWidgetComponent.vue'
import displayHuman from '@/mixin/displayHuman'

export default {
    name: 'DeviceTraitedDataByDeviceReferentielMetricDatatableWidgetComponent',
    components: { HeaderWidgetComponent, DisplayDeviceBatteryLevelComponent, ChipsLastStatement },
    mixins: [displayHuman],
    props: {
        device: {
            type: Object,
            required: true,
        },
        is_open: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            headers: [],
            device_traited_data: [],
            fetching_dtd: true,

            page: 1,
            item_per_page: 5,
            total_rows: 0,

            downloading_device_data_csv: false,
        }
    },
    methods: {
        fetchDeviceTraitedData() {
            this.fetching_dtd = true
            this.device_traited_data = []
            DeviceRepository.getHistoricalByDevice(
                this.device.device_number,
                new URLSearchParams({
                    datatable: true,
                    limit: this.item_per_page,
                    offset: (this.page - 1) * this.item_per_page,
                })
            )
                .then((success) => {
                    this.total_rows = success.data.data.data.total_rows
                    this.device_traited_data = success.data.data.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.fetching_dtd = false
                })
        },

        buildHeadersForDeviceMetricsDatatable() {
            this.headers.push({
                text: this.$t('device_traited_data_by_device_referentiel_metric_datatable_widget_component.reading_date'),
                value: 'date_releve',
                sortable: false,
            })

            this.device?.metrics.forEach((metric) => {
                if (!metric.display_only_for_admin && metric.visible) {

                    let label;
                    if(metric.metric.column_name_dtd === 'quality_network') {
                        label = this.$t('device_traited_data_by_device_referentiel_metric_datatable_widget_component.quality_network');
                    } else {
                        if(metric.metric.label === 'hauteur_liquide') { //Refacto : traduction on back end
                            metric.metric.label = this.$t('device_traited_data_by_device_referentiel_metric_datatable_widget_component.liquid_height');
                        }

                        label = metric.metric.unit ? `${metric.metric.label} (${metric.metric.unit})` : metric.metric.label
                    }


                    this.headers.push({
                        text: label,
                        value: metric.metric.column_name_dtd,
                        sortable: false,
                    })
                }
            })

            this.fetchDeviceTraitedData()
        },

        handlePageUpdate(page) {
            this.page = page
            this.fetchDeviceTraitedData()
        },

        handleItemsPerPageUpdate(item_per_page) {
            this.item_per_page = item_per_page
            this.fetchDeviceTraitedData()
        },

        downloadDeviceDataCsv() {
            this.downloading_device_data_csv = true
            DeviceRepository.getDeviceTraitedDataCSVByDeviceNumber(this.device.device_number).then(success => {
                let anchorElement = window.document.createElement('a')
                anchorElement.href = URL.createObjectURL(success.data)
                anchorElement.target = '_blank'
                anchorElement.download = `${this.device.device_number}_${this.moment().format(this.date_format)}.csv`
                document.body.appendChild(anchorElement)
                anchorElement.click()
            }).catch((error) => {
                this.manageError(error)
            }).finally(() => {
                this.downloading_device_data_csv = false
            })
        }
    },
    created() {
        this.buildHeadersForDeviceMetricsDatatable()
    },
}
</script>