import axios from '../plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IServerResponse } from '@/models/IServerResponse'
import { IConsumption } from '@/models/IConsumption'
import { IConsumptionBetweenDate } from '@/models/IConsumptionBetweenDate'

const resource = 'api/v1/module'

export default {
    postDeviceVariationsByIdDevice(
        id_module: number,
        id_device: number,
        data
    ): Promise<AxiosResponse<IServerResponse<Array<IConsumption>>>> {
        return axios.post(`${resource}/${id_module}/device/${id_device}/variations`, data)
    },

    postConsumptionByDates(
        id_module: number,
        id_device: number,
        data: { date_begin: string; date_end: string; interval: string },
    ): Promise<AxiosResponse<IServerResponse<Array<Array<IConsumptionBetweenDate>>>>> {
        return axios.post(`${resource}/${id_module}/device/${id_device}/averageConsumptionBetweenDateWithInterval`, data)
    },

    postModuleDonut(
        id_module: number,
        id_device: number,
        configuration
    ): Promise<
        AxiosResponse<
            IServerResponse<{
                series: Array<{ percent?: number; label: string; value: number }>
            }>
        >
    > {
        return axios.post(`${resource}/${id_module}/device/${id_device}/donut`, { configuration: configuration })
    },
}
