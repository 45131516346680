<template>
    <div id="date-range-picker-component">
        <date-range-picker
            ref="picker"
            v-model="local_date_range"
            :locale-data="locale"
            :maxDate="moment().toDate()"
            :opens="is_mobile ? 'center' : 'left'"
            :ranges="ranges"
            :singleDatePicker="is_mobile ? 'range' : false"
            append-to-body
            range
            v-on:update="update"
        >
            <template v-slot:input="picker">
                <v-row class="ma-0 pa-1">
                    <v-icon>mdi-calendar</v-icon>
                    <div class="ml-2 w-80">
                        <span class="font-color-medium font-12">
                            {{ $t('date_range_picker_component.selected_period') }}
                        </span>
                        <br />
                        <span>
                            {{ $t('date_range_picker_component.from') }}
                            {{ moment(picker.startDate).format(date_format_date_picker) || moment().format(date_format_date_picker) }}
                            {{ $t('date_range_picker_component.to') }}
                            {{ moment(picker.endDate).format(date_format_date_picker) || moment().format(date_format_date_picker) }}
                        </span>
                    </div>
                </v-row>
            </template>

            <template v-slot:footer="{ rangeText, clickCancel, clickApply }">
                <div class="calendar-footer">
                    <div>
                        <slot name="action-extra"/>
                    </div>

                    <div>
                        <span v-if="!is_mobile" class="font-10">{{ rangeText }}</span>

                        <v-btn small text @click="clickCancel">
                            <span class="btn-secondary">{{ $t('global.cancel') }}</span>
                        </v-btn>

                        <v-btn small text @click="clickApply">
                            <span
                                :style="`color: ${$store.getters['display_option/display_option'].secondary} !important`"
                                class="btn-success"
                            >
                                {{ $t('global.validate') }}
                            </span>
                        </v-btn>
                    </div>
                </div>
            </template>
        </date-range-picker>

        <v-progress-linear :color="loading ? 'primary' : '#939598'" :indeterminate="loading" absolute bottom height="2px" />
    </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
    name: 'DateRangePickerComponent',
    components: { DateRangePicker },
    props: {
        date_range: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    created() {
        this.ranges[this.$t('date_range_picker_component.today')] = [
            this.moment().startOf('day').toDate(),
            this.moment().endOf('day').toDate(),
        ]
        this.ranges[this.$t('date_range_picker_component.yesterday')] = [
            this.moment().subtract(1, 'days').startOf('day').toDate(),
            this.moment().subtract(1, 'days').endOf('day').toDate(),
        ]
        this.ranges[this.$t('date_range_picker_component.7_last_days')] = [
            this.moment().subtract(6, 'days').startOf('day').toDate(),
            this.moment().toDate(),
        ]
        this.ranges[this.$t('date_range_picker_component.30_last_days')] = [
            this.moment().subtract(30, 'days').startOf('day').toDate(),
            this.moment().toDate(),
        ]
        this.ranges[this.$t('date_range_picker_component.3_last_months')] = [
            this.moment().subtract(3, 'month').startOf('day').toDate(),
            this.moment().toDate(),
        ]
        this.ranges[this.$t('date_range_picker_component.6_last_months')] = [
            this.moment().subtract(6, 'month').startOf('day').toDate(),
            this.moment().toDate(),
        ]
        this.ranges[this.$t('date_range_picker_component.this_month')] = [
            this.moment().startOf('month').toDate(),
            this.moment().endOf('month').toDate(),
        ]
        this.ranges[this.$t('date_range_picker_component.last_month')] = [
            this.moment().subtract(1, 'month').startOf('month').toDate(),
            this.moment().subtract(1, 'month').endOf('month').toDate(),
        ]
        this.ranges[this.$t('date_range_picker_component.last_year')] = [this.moment().subtract(1, 'year').toDate(), this.moment().toDate()]
        this.ranges[this.$t('date_range_picker_component.this_year')] = [this.moment().startOf('year').toDate(), this.moment().toDate()]
        this.local_date_range = this.date_range
    },
    data() {
        return {
            local_date_range: {
                startDate: null,
                endDate: null,
            },
            locale: {
                format: 'mm/dd/yyyy',
                applyLabel: this.$t('date_range_picker_component.apply'),
                cancelLabel: this.$t('date_range_picker_component.cancel'),
                fromLabel: this.$t('date_range_picker_component.from'),
                toLabel: this.$t('date_range_picker_component.to'),
                customRangeLabel: this.$t('date_range_picker_component.personalize'),
                daysOfWeek: [
                    this.$t('date_range_picker_component.sunday').slice(0, 3),
                    this.$t('date_range_picker_component.monday').slice(0, 3),
                    this.$t('date_range_picker_component.tuesday').slice(0, 3),
                    this.$t('date_range_picker_component.wednesday').slice(0, 3),
                    this.$t('date_range_picker_component.thursday').slice(0, 3),
                    this.$t('date_range_picker_component.friday').slice(0, 3),
                    this.$t('date_range_picker_component.saturday').slice(0, 3),
                ],
                monthNames: [
                    this.$t('date_range_picker_component.january'),
                    this.$t('date_range_picker_component.february'),
                    this.$t('date_range_picker_component.march'),
                    this.$t('date_range_picker_component.april'),
                    this.$t('date_range_picker_component.may'),
                    this.$t('date_range_picker_component.june'),
                    this.$t('date_range_picker_component.july'),
                    this.$t('date_range_picker_component.august'),
                    this.$t('date_range_picker_component.september'),
                    this.$t('date_range_picker_component.october'),
                    this.$t('date_range_picker_component.november'),
                    this.$t('date_range_picker_component.december'),
                ],
            },

            ranges: {},
        }
    },
    methods: {
        update() {
            this.$emit('update', this.local_date_range)
        },
    },
}
</script>

<style>
#date-range-picker-component {
    position: relative;
}

.vue-daterange-picker {
    display: block !important;
}

.daterangepicker {
    font-family: 'Sarabun', serif !important;
}

.vue-daterange-picker .reportrange-text {
    background: #0000000f !important;
    border: none;
}

@media screen and (max-width: 540px) {
    .drp-buttons {
        display: grid !important;
        grid-column: 3;
    }

    .drp-selected {
        grid-column: 3;
        margin-right: 5px;
        text-align: center;
    }
}

@media screen and (max-width: 960px) {
    .daterangepicker .calendars .ranges ul {
        display: flex !important;
        flex-wrap: wrap;
    }

    .daterangepicker .calendars-container {
        margin-inline: auto;
    }

    .daterangepicker .calendars-container .drp-calendar {
        border: none !important;
    }
}

.daterangepicker .calendar-footer {
    padding: 8px;
    & div:nth-child(2)  {
        display: flex;
        align-items: center;
        justify-content: end;
    }
}

.daterangepicker .btn-secondary {
    background: none;
    text-transform: uppercase;
    color: var(--v-default_text-base) !important;
    font-weight: bold;
    margin-bottom: 3px;
}

.daterangepicker .btn-success {
    background: none;
    text-transform: uppercase;
    color: var(--v-secondary-base);
    font-weight: bold;
    margin-bottom: 3px;
}

.daterangepicker td.active {
    background: var(--v-secondary-base) !important;
}

.daterangepicker td.off {
    background: none !important;
}

.daterangepicker td.start-date {
    background: var(--v-secondary-base);
}

.daterangepicker td.in-range {
    background: var(--v-secondary-lighten3);
}

.daterangepicker .ranges li.active {
    background: var(--v-secondary-base) !important;
}

.calendars {
    color: #000;
}
</style>
