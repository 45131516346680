<template>
    <div id="list-tank-component" class="pa-4">
        <!-- tanks.filter(tank => tank.is_subscription_device_active).length > 0 ? -->
        <template v-if="!loading_tanks">
            <template v-if="tanks.length > 0">
                <v-text-field
                    v-model="search"
                    :label="$t('global.search')"
                    class="white-text-field mb-2"
                    filled
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                />

                <p class="font-weight-bold mb-4">
                    {{ $t('list_tank_equipmement_component.have_x_cuve', [tanks_copy.length]) }}
                </p>

                <v-row>
                    <v-col v-for="(tank, index) in tanks_copy" :key="tank.id_asset" class="mt-2" cols="6" lg="3" md="4" xl="2">
                        <div class="pointer h-100" @click="displayDetailAsset(index)">
                            <asset-card-minimalist-component :asset="tank" :is_selected="index === index_asset_selected" />
                        </div>
                    </v-col>

                    <v-col v-if="userHasAccess('TNK', role_admin)" class="mt-2" cols="6" lg="3" md="4" xl="2">
                        <v-btn v-if="is_mobile" :href="getTunnelInstallationUrl()" block data-cy="add-asset-btn" outlined rounded small>
                            <v-icon left> mdi-plus</v-icon>
                            <span class="font-color-medium text-uppercase">{{ $t('global.add') }}</span>
                        </v-btn>

                        <a v-else :href="getTunnelInstallationUrl()" class="add-asset-btn" data-cy="add-asset-btn">
                            <v-icon class="font-color-medium">mdi-plus</v-icon>
                            <span class="font-color-medium text-uppercase">{{ $t('list_tank_equipmement_component.add_tank') }}</span>
                        </a>
                    </v-col>
                </v-row>

                <template v-if="!reload">
                    <div class="mt-8">
                        <v-alert
                            v-if="
                                !tanks_copy[index_asset_selected].is_all_subscription_device_active &&
                                tanks_copy[index_asset_selected].is_subscription_device_active
                            "
                            icon="mdi-alert-outline"
                            text
                            type="warning"
                        >
                            <template v-for="device in tanks_copy[index_asset_selected].devices">
                                <p
                                    v-if="!device.is_subscription_active"
                                    :key="device.id_device"
                                    class="font-weight-regular font-14 warning&#45;&#45;text line-height-18 mb-0"
                                >
                                    {{ $t('list_tank_equipmement_component.subscription_device_expired_on', [device.device_number]) }}
                                    <strong>{{ moment(device.date_subscription_end).format('DD MMMM YYYY') }}</strong>.
                                    <br />
                                </p>
                            </template>

                            <p class="font-weight-regular font-14 warning&#45;&#45;text line-height-18 mb-0">
                                {{ $t('list_tank_equipmement_component.to_access_detail_reactivate_subscription') }}
                                <a class="warning&#45;&#45;text font-weight-bold" href="http://dashboard.fuel-it.io/" target="_blank">
                                    http://dashboard.fuel-it.io/
                                </a>
                            </p>
                        </v-alert>

                        <v-alert
                            v-else-if="!tanks_copy[index_asset_selected].is_all_subscription_device_active"
                            icon="mdi-alert-outline"
                            text
                            type="warning"
                        >
                            <p class="font-weight-regular font-14 warning--text line-height-18 mb-0">
                                {{ $t('list_tank_equipmement_component.subscription_expired') }}
                                {{
                                    tanks_copy[index_asset_selected].devices?.length > 1
                                        ? ' ' + $t('list_tank_equipmement_component.for_all_devices')
                                        : ''
                                }}.
                                {{ $t('list_tank_equipmement_component.to_access_detail_reactivate_subscription') }}
                                <a class="warning--text font-weight-bold" href="http://dashboard.fuel-it.io/" target="_blank">
                                    http://dashboard.fuel-it.io/
                                </a>
                            </p>
                        </v-alert>

                        <details-tank-component
                            v-if="tanks_copy[index_asset_selected].is_subscription_device_active"
                            :tank="tanks_copy[index_asset_selected]"
                            v-on:refresh:tank="getTanksByClient"
                        />
                    </div>
                </template>
            </template>

            <template v-else>
                <empty-page-component
                    :link_img="'/images/error/empty_page.png'"
                    :main_text="$t('list_tank_equipmement_component.no_tank_yet')"
                    :secondary_text="$t('list_tank_equipmement_component.to_install_tank_click_button')"
                    :show_secondary_text="userHasAccess('TNK', role_admin)"
                />
            </template>

            <v-speed-dial
                v-if="userHasAccess('TNK', this.role_vision) || userHasAccess('BASE', this.role_vision)"
                v-model="settings"
                :class="is_mobile ? 'bottom-left-btn-mobile' : 'bottom-left-btn-desktop'"
                data-cy="btn-option-detail-asset"
                direction="top"
                transition="slide-y-reverse-transition"
            >
                <template v-slot:activator>
                    <v-btn v-model="settings" color="secondary" elevation="0" fab>
                        <v-icon v-if="settings" color="primary"> mdi-close</v-icon>

                        <v-icon v-else color="primary"> mdi-cog</v-icon>
                    </v-btn>
                </template>

                <v-btn
                    v-if="userHasAccess('TNK', role_admin)"
                    :href="getTunnelInstallationUrl()"
                    color="primary"
                    elevation="0"
                    fab
                    target="_blank"
                >
                    <v-icon color="secondary">mdi-plus</v-icon>
                </v-btn>

                <v-btn
                    v-if="userHasAccess('BASE', this.role_vision)"
                    :to="{ path: '/equipment/manage_alert/' + tanks_copy[index_asset_selected].id_asset }"
                    color="primary"
                    data-cy="manage-alert-btn"
                    elevation="0"
                    fab
                >
                    <v-icon color="secondary">mdi-bell-ring</v-icon>
                </v-btn>

                <v-btn
                    v-if="userHasAccess('TNK', this.role_vision)"
                    color="primary"
                    data-cy="manage-widgets-btn"
                    elevation="0"
                    fab
                    @click="$refs.DialogManageWidgetComponent.dialog_manage_widgets = true"
                >
                    <v-icon color="secondary">mdi-chart-box-plus-outline</v-icon>
                </v-btn>

                <v-btn
                    v-if="userHasAccess('TNK', this.role_admin)"
                    color="primary"
                    data-cy="edit-asset-btn"
                    elevation="0"
                    fab
                    @click="$refs.manageEquipmentComponent.openManageEquipmentDialogComponent(tanks_copy[index_asset_selected])"
                >
                    <v-icon color="secondary">mdi-pencil</v-icon>
                </v-btn>
            </v-speed-dial>
            <div class="bottom-hidden-div" />
        </template>

        <template v-else>
            <v-row>
                <v-col v-for="n in 2" :key="n" class="mt-2" cols="4" lg="3" md="4" xl="2">
                    <div class="h-100">
                        <v-skeleton-loader v-if="is_mobile" type="text" />
                        <v-skeleton-loader v-else type="image" />
                    </div>
                </v-col>
            </v-row>

            <v-row class="mt-8">
                <v-col v-for="n in 4" :key="n" cols="12" lg="6">
                    <v-skeleton-loader type="card-heading, list-item-two-line, image, table-thead" />
                </v-col>
            </v-row>
        </template>

        <manage-equipment-devices-component
            v-if="userHasAccess('TNK', this.role_edit)"
            ref="manageEquipmentDevices"
            data-cy="manage-equipment-devices"
        />

        <manage-equipment-dialog-component
            v-if="userHasAccess('TNK', this.role_admin)"
            ref="manageEquipmentComponent"
            data-cy="manage-equipment-component"
            v-on:refresh:tank="getTanksByClient"
        />

        <dialog-manage-widget-component
            v-if="userHasAccess('TNK', role_vision)"
            ref="DialogManageWidgetComponent"
            data-cy="dialog-manage-widget-component"
        />
    </div>
</template>

<script>
import ManageEquipmentDevicesComponent from '@/components/EquipmentManagement/ManageEquipmentDevicesComponent'
import ManageEquipmentDialogComponent from '@/components/EquipmentManagement/ManageEquipmentDialogComponent.vue'
import EmptyPageComponent from '@/components/Error/EmptyPageComponent'
import AssetCardMinimalistComponent from '@/components/Equipment/Card/AssetCardMinimalistComponent.vue'
import DetailsTankComponent from '@/components/Equipment/Details/DetailsTankComponent.vue'
import DialogManageWidgetComponent from '@/components/Widget/DialogManageWidgetComponent.vue'

export default {
    name: 'ListTankEquipmentComponent',
    components: {
        DialogManageWidgetComponent,
        DetailsTankComponent,
        AssetCardMinimalistComponent,
        EmptyPageComponent,
        ManageEquipmentDialogComponent,
        ManageEquipmentDevicesComponent,
    },
    props: {
        asset_type: {
            type: Object,
            required: true,
        },
    },
    created() {
        this.getTanksByClient()
    },
    data() {
        return {
            loading_tanks: false,
            selected_tank: {},
            edit_tank_bottom_sheet: false,
            dialog_manage_devices: false,

            tanks_copy: [],

            search: '',
            tank_selected: {},

            fav: false,
            settings: false,
            tank_image_settings: {
                1: {
                    path: 'tank_cylindrical.png',
                    height: 180,
                },
                2: {
                    path: 'tank_rectangular.png',
                    height: 100,
                },
                3: {
                    path: 'IBC.png',
                    height: 120,
                },
                default: {
                    path: 'tank.png',
                    height: 100,
                },
            },
            is_new_alert: false,
            dialog_alert: {},
            index_asset_selected: 0,
            reload: false,
        }
    },
    computed: {
        tanks: {
            get: function () {
                return this.$store.getters['tanks/tanks']
            },
            set: function (value) {
                this.$store.dispatch('tanks/getTanksByClient', this.$store.getters['user_connected/user_connected'].id_client)
            },
        },
    },
    methods: {
        displayDetailAsset(index_asset_selected) {
            this.reload = true
            this.index_asset_selected = index_asset_selected
            setTimeout(() => {
                this.reload = false
            }, 50)
        },

        getTanksByClient() {
            this.loading_tanks = true

            this.$store
                .dispatch('tanks/getTanksByClient', {
                    id_client: this.$store.getters['user_connected/user_connected'].id_client,
                    id_referential: this.asset_type.id_referential,
                })
                .then(() => {
                    this.tanks_copy = JSON.parse(JSON.stringify(this.tanks))
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_tanks = false
                })
        },

        swipeBottomHandler() {
            this.edit_tank_bottom_sheet = false
        },
    },
    watch: {
        search: {
            handler() {
                if (!this.search) {
                    this.tanks_copy = this.tanks
                } else {
                    let tanks_local = []
                    this.tanks.forEach((tank) => {
                        if (tank.name.includes(this.search)) {
                            tanks_local.push(tank)
                        }
                    })

                    this.tanks_copy = tanks_local
                }
            },
            deep: true,
        },
    },
}
</script>